import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import { computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { NavLink } from 'react-router-dom';
import { IUserSessionComponentProps, UserSessionViewModelKey } from '../../../../models/AppState';
import { IEventLoggingComponentProps, withEventLogging } from '../../../../models/Logging';
import { getDefaultDateStringValue, getDefaultTimeStringValue } from '../../../../models/UiUtils';
import {
	ComposeEmailToWithGuideViewModel,
	ComposeEmailViewModel,
	ComposeResourceEmailViewModel,
	KeyDateKind,
	ResourceSelectorId,
	SendEmailFrom,
} from '../../../../viewmodels/AppViewModels';
import { baseStyleSheet } from '../../../styles/styles';
import { ScheduleSendIcon } from '../../svgs/icons/ScheduleSendIcon';
import { SuccessCheckIcon } from '../../svgs/icons/SuccessCheckIcon';
import { styleSheet } from './styles';

export interface ISendSchedulerCompleteProps extends IEventLoggingComponentProps, IUserSessionComponentProps {
	className?: string;
	emailComposer?: ComposeEmailViewModel;
	emailComposerWithGuide?: ComposeEmailToWithGuideViewModel;
	emailCount: number;
	estimate?: Partial<Api.IEmailSendEstimate>;
	onNavigateToReportingGroupEmail?(e: React.MouseEvent<HTMLElement>): void;
}

class _SendSchedulerComplete extends React.PureComponent<ISendSchedulerCompleteProps & RouteComponentProps<any>> {
	public render() {
		const { className, estimate, userSession } = this.props;

		return (
			<div className={`${css(styleSheet.container)} send-scheduler-complete ${className || ''}`}>
				<div className={css(styleSheet.iconContainer)}>
					<ScheduleSendIcon className={css(styleSheet.icon)} />
					<SuccessCheckIcon className={css(styleSheet.successIcon)} />
				</div>
				{this.isForBirthday ? (
					<div className={css(styleSheet.titleMessage)}>
						Birthday emails will be sent on the morning of the recipient&apos;s actual birthday.
					</div>
				) : estimate ? (
					<div className={css(styleSheet.titleMessage)}>
						{`Your emails will start to send on ${getDefaultDateStringValue(
							estimate.estimate.startDate
						)} at ${getDefaultTimeStringValue(estimate.estimate.startDate)}.`}
					</div>
				) : null}
				{this.renderWhoWillBeNotified()}
				<div className={css(styleSheet.detailMessage)}>
					<div>
						{userSession?.account?.features?.htmlNewsletter?.useClientEmailProvider ? this.renderScheduleInfo() : null}
						{this.isForRenewal
							? `Once the renewals are scheduled, you can manage them in the `
							: `You can view the queued up email in`}
						&nbsp;
						<NavLink
							className={css(baseStyleSheet.brandLink, styleSheet.link)}
							onClick={this.onGroupEmailLinkClicked}
							to='/reporting/group-email'
						>
							<span>{this.isForRenewal ? 'Email Reports section' : 'Group Email Reports'}</span>
						</NavLink>
						.
					</div>
				</div>
			</div>
		);
	}

	@computed
	private get isForBirthday() {
		const { emailComposer, emailComposerWithGuide } = this.props;
		if (emailComposer) {
			const resourceVM = emailComposer as ComposeResourceEmailViewModel;
			return resourceVM?.resourceSelector === ResourceSelectorId.HappyBirthday;
		} else if (emailComposerWithGuide) {
			return emailComposerWithGuide?.keyFact?.keyDate?.kind === KeyDateKind.Birthday;
		}
		return false;
	}

	@computed
	private get isForRenewal() {
		const { emailComposer, emailComposerWithGuide } = this.props;
		if (emailComposer) {
			const resourceVM = emailComposer as ComposeResourceEmailViewModel;
			return resourceVM?.resourceSelector === ResourceSelectorId.PolicyRenew;
		} else if (emailComposerWithGuide) {
			return emailComposerWithGuide?.keyFact?.keyDate?.kind === KeyDateKind.Renewal;
		}
		return false;
	}

	private renderWhoWillBeNotified = () => {
		const { emailComposer } = this.props;
		let who: string;

		if (emailComposer?.emailMessage) {
			const isSendFromOption = emailComposer?.emailMessage?.isSendFromOption;

			if (isSendFromOption) {
				if (isSendFromOption(SendEmailFrom.ContactOwner) && emailComposer.ownersWithFirstNames?.length > 0) {
					who = emailComposer.ownersWithFirstNames
						.map(
							(owner, i) =>
								`${
									i === emailComposer.ownersWithFirstNames.length - 1 && emailComposer.ownersWithFirstNames.length > 1
										? 'and '
										: ''
								}${owner.user.firstName} (${owner.contactsCount})`
						)
						.join(emailComposer.ownersWithFirstNames.length > 2 ? ', ' : ' ');
				} else if (isSendFromOption(SendEmailFrom.SelectedUser)) {
					who = `${emailComposer.emailMessage.options.sendEmailFromUser.firstName}`;
				}
			}
		}

		return who ? (
			<div className={css(styleSheet.titleMessage, styleSheet.whoWillBeNotified)}>
				{`${who} will be notified via email.`}
			</div>
		) : null;
	};

	private renderScheduleInfo() {
		const { estimate, emailCount, emailComposer } = this.props;
		const estimatedDaysToComplete = this.numberOfDaysToFinish;
		const recipientsCount = emailComposer?.followUpSource
			? emailComposer?.followUpSource instanceof Api.SurveyReportViewModel
				? emailComposer?.recipients?.length
				: emailComposer.followUpCount - emailComposer.emailMessage?.options?.followUp?.excludeContactIds?.length
			: emailCount;

		if (!!estimate && estimatedDaysToComplete > 0) {
			const dailyLimit = this.dailySendLimit;
			return (
				<>
					{!!dailyLimit && <div>{`Your email server has a daily limit of ${dailyLimit.count} emails per day.`}</div>}
					<div>
						{`Levitate will send the ${recipientsCount} emails over ${estimatedDaysToComplete} day${
							estimatedDaysToComplete > 1 ? 's' : ''
						}.`}
					</div>
				</>
			);
		}
		return null;
	}

	private get dailySendLimit() {
		const { emailComposer, estimate } = this.props;

		// Return null if minimum duration in days is what is the primary factor in send limits.
		if (emailComposer?.emailMessage?.options?.minimumDurationInDays > 0) {
			return null;
		}

		return estimate ? estimate?.emailWorkload?.limits?.find(x => x.intervalInMinutes === 1440) : null;
	}

	private get numberOfDaysToFinish() {
		const { emailComposer, estimate } = this.props;
		let value = -1;
		if (emailComposer?.emailMessage?.options?.minimumDurationInDays > 0) {
			value = emailComposer.emailMessage.options.minimumDurationInDays;
		} else if (emailComposer?.emailMessage?.sendLimits?.length > 0) {
			value = Math.ceil((emailComposer.estimatedRecipientsTotal || 1) / emailComposer.emailMessage.sendLimits[0].count);
		}

		if (value < 0) {
			value =
				!!estimate && !!estimate.estimate
					? Math.ceil(moment(estimate.estimate.endDate).diff(moment(estimate.estimate.startDate), 'days', true))
					: -1;
		}

		return value < 0 ? 0 : value;
	}

	private onGroupEmailLinkClicked = (e: React.MouseEvent<HTMLAnchorElement>) => {
		const { logInput, onNavigateToReportingGroupEmail } = this.props;
		logInput('ReportingGroupEmailLink', 'Click');
		if (onNavigateToReportingGroupEmail) {
			onNavigateToReportingGroupEmail(e);
		}
	};
}
const SendSchedulerCompleteAsObserver = observer(_SendSchedulerComplete);
const SendSchedulerCompleteWithContext = inject(UserSessionViewModelKey)(SendSchedulerCompleteAsObserver);
export const SendSchedulerComplete = withRouter(
	withEventLogging(SendSchedulerCompleteWithContext, 'SendSchedulerComplete')
);
